import React, { FC, useState, useCallback, ReactElement } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import cn from "classnames";

import track from "~/utils/track";
import { buildHtmlContentForPdf } from "./pdfBuilder";

const PDF_CONVERTER_URL = process.env.PDF_CONVERTER_URL;
const PDF_CONVERTER_API_KEY = process.env.PDF_CONVERTER_API_KEY;

export type Props = {
  label: string;
  classes: string;
};

type ButtonState = {
  error: boolean;
  success: boolean;
  loading: boolean;
};

// convert the current page title into a filename.
// if urlSafe is true also replace any non-word chars with underscores (e.g. '?' which can break urls)
const getFilename = (urlSafe = true): string => {
  let filename = document.title.split("|")[0].trim();
  if (urlSafe) {
    filename = filename.replace(/\W+/g, "_");
  }

  return `${filename}.pdf`;
};

// call the cloud function to get the PDF url back
const convertToPdf = async (pageHtml: string): Promise<Blob> => {
  const res = await axios.post(
    PDF_CONVERTER_URL,
    {
      html: pageHtml,
      filename: getFilename(),
    },
    {
      responseType: "blob",
      headers: {
        "X-Api-Key": PDF_CONVERTER_API_KEY,
      },
    }
  );

  return new Blob([res.data], { type: "application/pdf" });
};

// create and click a link to the file.
// depending on browser/device/phase of the moon it will either open in a new tab or download
const downloadOrOpen = (fileBlob: Blob): void => {
  const filename = getFilename();

  // IE support
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    window.navigator.msSaveBlob(fileBlob, filename);
  } else {
    // modern browser support
    const fileUrl =
      URL && URL.createObjectURL
        ? URL.createObjectURL(fileBlob)
        : window.webkitURL.createObjectURL(fileBlob);

    const linkNode = document.createElement("a");
    linkNode.style.display = "none";
    linkNode.href = fileUrl;
    linkNode.setAttribute("download", filename);
    linkNode.setAttribute("target", "_blank");
    document.body.appendChild(linkNode);

    linkNode.click();

    setTimeout(function () {
      document.body.removeChild(linkNode);
      URL.revokeObjectURL(fileUrl);
    }, 500);
  }
};

const DownloadAsPdf: FC<Props> = ({ label, classes }): ReactElement => {
  const [buttonState, setButtonState] = useState<Partial<ButtonState>>({
    error: false,
    success: false,
    loading: false,
  });

  // return button state to idle shortly after error/success was returned
  const revertAfterDelay = useCallback(() => {
    setTimeout(() => setButtonState({ error: false, success: false }), 2000);
  }, [setButtonState]);

  // util function to keep category and label consistent across download events
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackDownload = (action: string, value?: any): void =>
    track({
      category: "download",
      action: action,
      label: getFilename(false), // false = don't strip punctuation etc
      value,
    });

  const handleClick = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();

      // send analytics on download
      trackDownload("click");

      const newButtonState: Partial<ButtonState> = {
        loading: false,
        error: false,
        success: false,
      };

      setButtonState({ loading: true });
      try {
        const pageHtml = buildHtmlContentForPdf();
        const fileBlob = await convertToPdf(pageHtml);
        downloadOrOpen(fileBlob);
        newButtonState.success = true;
        trackDownload("success");
      } catch (e) {
        console.error("Failed to fetch PDF:", e);
        newButtonState.error = true;

        trackDownload("error", { errorMessage: e.message });
      } finally {
        setButtonState(newButtonState);
        revertAfterDelay();
      }
    },
    [convertToPdf, downloadOrOpen, setButtonState, revertAfterDelay]
  );

  const buttonLabel = buttonState.loading ? "Fetching PDF..." : label;
  const { loading, error, success } = buttonState;

  return (
    <button
      className={cn(classes, { error, success })} // TODO: find and re-use existing error/success styling
      onClick={handleClick}
      type="button"
      disabled={loading || error}
    >
      {buttonLabel}
    </button>
  );
};

DownloadAsPdf.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

export default DownloadAsPdf;
