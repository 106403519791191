// hide a bunch of non-content irrelevant stuff like search buttons and nav links
const selectorsToHide: Array<string> = [
  "#topBar",
  ".resources-social-sharing",
  "#nav-toggle",
  ".handle--search",
  ".resources-main-content__section.buttons",
  ".resources-page-divider",
  ".show-more-link",
  ".mobile-resource-nav__item",
];

// max height of activities that fit on a page
const pageMaxHeight = 800; // approx height of a4 content

// apply the hide class to any elements not relevant for the pdf
const markNonContentElementsAsHidden = (parent: HTMLElement): void => {
  for (const selector of selectorsToHide) {
    for (const elem of Array.from(parent.querySelectorAll(selector))) {
      elem.classList.add("hide-for-pdf");
    }
  }
};

// PDF service can't render embedded `object` svgs. Convert them to standard `img` tags
const replaceHeaderSvg = (parent: HTMLElement): void => {
  const logos = Array.from(
    parent.querySelectorAll(".ro-header-logo-logo-button object")
  );
  for (const headerLogo of logos) {
    const parent = headerLogo.parentNode;
    const sourceUrl = headerLogo.getAttribute("data");
    const headerImg = document.createElement("img");
    headerImg.src = sourceUrl;
    headerImg.className = "header-svg-image";
    parent.replaceChild(headerImg, headerLogo);
  }
};

// The related items have a carousel in tablet view. Remove that and make a simple grid.
const cleanUpRelatedItems = (relatedItems: HTMLElement): HTMLElement => {
  const container = relatedItems.cloneNode(true) as HTMLElement;
  const cardTrack = container.querySelector(".card-track") as HTMLElement;
  cardTrack.removeAttribute("data-card-position");
  cardTrack.style.display = "grid";
  cardTrack.style.gridTemplateColumns = "1fr 1fr";
  cardTrack.style.gridGap = "32px";
  container.appendChild(cardTrack); // change position (hopefully)
  const cards = Array.from<HTMLElement>(
    container.querySelectorAll(".resource-card")
  );
  for (const card of cards) {
    const image = card.querySelector(".image") as HTMLElement;
    image.style.height = "150px";
    image.style.backgroundSize = "cover";
    card.style.width = "auto";
    card.style.maxWidth = "unset";
  }

  container.setAttribute("displayHeight", "500px");

  return container;
};

// Allow specifying a calculated height for certain elements.
// Gets around issues in tablet view with intro, footer and related items having large height differences
const cloneWithHeight = (
  selector: string,
  heightFn: (elem?: HTMLElement) => number
): HTMLElement => {
  const sourceNode = document.querySelector(selector) as HTMLElement;
  const elem = sourceNode.cloneNode(true) as HTMLElement;

  elem.setAttribute("displayHeight", `${heightFn(sourceNode)}px`);

  return elem;
};

// if the element has been cloned it will have 0 client height - so it should have a custom 'displayHeight' attribute set
const getElementHeightInPdf = (elem: HTMLElement): number => {
  // NOTE: due to using the current element height here the output PDF can be slightly different depending on
  //       whether the user access this feature from mobile, tablet or desktop.

  // clientHeight is the current height, need to adjust towards PDF page size
  // these magic numbers allow us to more accurately pack the content into pages
  const heightModifier = window.innerWidth < 768 ? 0.4 : 0.75; // for small screens content is really tall, so shrink measurement a lot
  const clientHeight = elem.clientHeight * heightModifier;
  const styleHeight = elem.getAttribute("displayHeight")
    ? parseInt(elem.getAttribute("displayHeight").replace("px", ""))
    : 0;

  return styleHeight || clientHeight;
};

// split list of elements into 'pages' that can be displayed separately in the PDF
// attempts to fill pages with blocks of content until the height reaches approx A4 size
const paginateElementsIntoNewRoot = (
  elements: Array<HTMLElement>,
  parent: HTMLElement
): void => {
  const pages: Array<Array<HTMLElement>> = [];
  let pageItems: Array<HTMLElement> = [];
  let pageItemHeight = 0;

  for (const elem of elements) {
    const elemHeight = getElementHeightInPdf(elem);
    if (pageItemHeight + elemHeight > pageMaxHeight) {
      pages.push(pageItems);
      pageItems = [];
      pageItemHeight = 0;
    }
    pageItems.push(elem.cloneNode(true) as HTMLElement);
    pageItemHeight += elemHeight;
  }

  // add the remaining items to the final page
  if (pageItems.length > 0) {
    pages.push(pageItems);
  }

  // wrap each group of elements into a page container which enforces the a4 sizing
  for (const items of pages) {
    const page = document.createElement("div");
    page.classList.add("page");
    for (const elem of items) {
      page.appendChild(elem);
    }
    parent.appendChild(page);
  }
};

// rather than editing the current page and generating a pdf off of that, create a separate document
// this allows more modification and robustness, without affecting the currently viewed page
const createDocumentForPdf = (): Array<HTMLElement> => {
  const parent = document.implementation.createHTMLDocument("pdf");
  const root = parent.querySelector("html");
  for (const elem of Array.from(document.head.childNodes)) {
    parent.head.appendChild(elem.cloneNode(true));
  }

  const pageWrapper = parent.createElement("div");
  pageWrapper.classList.add("page-wrapper");
  parent.body.appendChild(pageWrapper);

  return [root, pageWrapper];
};

// Extract and style the relevant content from the current page to build the pdf.
// Returns the HTML as a string to be sent to the service.
export const buildHtmlContentForPdf = (): string => {
  const elements: Array<HTMLElement> = [];

  const [root, pageWrapper] = createDocumentForPdf();

  // float the footer to the end of whatever page it's on
  const footer = cloneWithHeight(".wrapper--ro-footer", () => 300);
  footer.style.marginTop = "auto";
  footer.classList.remove("wrapper--ro-footer"); // remove class to disable styles

  // extract the relevant content from the current page
  elements.push(
    document.querySelector(".navigation"),
    document.querySelector(".resources-page-title"),
    cloneWithHeight(
      ".resources-intro-content",
      // the image on table has fixed 200 height, so set the packing height accordingly
      (elem) => 200 + elem.querySelector("p").clientHeight
    ),
    ...Array.from(
      document.querySelectorAll<HTMLElement>(
        ".resources-secondary-content > .resources-secondary-content__list"
      )
    ),
    document.querySelector(".resource-summary"),
    ...Array.from(
      document.querySelectorAll<HTMLElement>(".resources-main-content__section")
    ),
    cleanUpRelatedItems(
      document.querySelector(".resources-related-items") as HTMLElement
    ),
    footer
  );

  paginateElementsIntoNewRoot(elements, pageWrapper);

  replaceHeaderSvg(pageWrapper);
  markNonContentElementsAsHidden(pageWrapper);

  return root.outerHTML;
};
