/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This is a modified version of the tracker from the Vue utils.
 *
 * This utility returns an object that logs tracking events to both heap and google analytics.
 * it will pick up the global GA and Heap objects on the page, so they need to be correctly instantiated first.
 */

export type AnalyticsEvent = {
  category: string;
  action: string;
  label: string;
  value?: any;
};

declare global {
  interface Window {
    heap: any;
    ga: any;
  }
}

export default ({
  category,
  action,
  label,
  value = null,
}: AnalyticsEvent): void => {
  const { heap, ga } = window;

  if (ga) {
    try {
      const tracker = ga.getAll()[0];
      tracker.send(
        "event",
        category,
        action,
        label,
        // TODO: not 100% sure what this number check is for - afaik GA accepts arbitrary values
        Number.isInteger(value) ? value : null
      );
    } catch (ex) {
      console.warn(
        "Failed to send GA event:",
        category,
        action,
        label,
        "due to:",
        ex.message
      );
    }
  }

  if (heap) {
    try {
      let payload = {
        category,
        action,
        label,
      };

      if (value && typeof value === "object") {
        payload = {
          ...payload,
          ...value,
        };
      }
      heap.track(action, payload);
    } catch (ex) {
      console.warn(
        "Failed to send Heap event:",
        category,
        action,
        label,
        "due to:",
        ex.message
      );
    }
  }
};
