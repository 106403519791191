import React from "react";
import { render } from "react-dom";
import DownloadAsPdf from "../DownloadAsPdf";

const targets = document.getElementsByTagName("hwc-download-as-pdf");

Array.from(targets).forEach((elem) => {
  const classes = elem.getAttribute("classes");
  const label = elem.getAttribute("label");
  render(<DownloadAsPdf classes={classes} label={label} />, elem);
});
